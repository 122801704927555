.search-qr-code input:focus {
    background-color: #F4F4F4;
    border-color: white;
}

.search-qr-code .form-control:focus {
    box-shadow: unset;
}
.blue-text {
    color: #3161e4;
}

.qr-code_apps {
    background-color: #F4F4F4;
    min-height: 100vh;
}

.white-radius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.btn-right_arrow {
    margin-left: 12px;
}

.react-datepicker-wrapper {
    width: 100%;
}

i.material-icons.me-2.text-blue {
    color: #1961E4;
}

p.btc-align {
    position: absolute;
    top: 42px;
    right: 32px;
}


p.three-dots {
    height: 5px;
    width: 5px;
    background-color: #000000;
    border-radius: 50%;
    margin-left: 4px;
    margin-top: 5px;
    display: inline-block;
    font-weight: bold;
}

button.btn.expiry-date-btn {
    border: 1px solid #e0e0e0;
    color: black;
}

.addmore-box {
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 10px;
}

.option-color {
    color: #adadad;
}

span.mx-2.status {
    background-color: #E6FAEE;
    padding: 4px;
    color: #28C828;
    border: 1px solid #E6FAEE;
    border-radius: 5px;
}
span.mx-2.static {
    background-color: #e0e0e000;
    padding: 4px 9px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 5px;
}
span.mx-2.paused {
    background-color: #e0e0e0;
    padding: 4px 6px;
    color: #000000;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.stsearch-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ed2c2c00;
    border-radius: 5px;
}

span.selectall {
    font-size: 17px;
    margin-left: 5px;
    font-weight: 700;
}

p.format_box {
    background-color: #F4F4F4;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 12px;
}

button.megabite {
    background-color: #ffffff00;
    border: 1px solid #1961E4;
    border-radius: 4px;
    color: #1961E4;
    padding: 2px 8px;
    font-size: 12px;
}

button.megabite_deactive {
    background-color: #f4f4f4;
    border: 1px solid #8f8f8f00;
    border-radius: 4px;
    color: #8f8f8f;
    padding: 2px 8px;
    font-size: 12px;
}
p.sup {
    font-weight: 600;
}
.browser-span {
    border: 2px dashed #E0E0E0;
    height: 129px;
}

.type-dropdown {
    width: 160px;
}
.link_url {
    text-decoration: none;
    color: black;
}
span.ellipes {
    display: block;
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
}
label.font_color {
    color: #747474;
}

// div#canvas {
//     border: 20px solid rebeccapurple;
//     border-radius: 10%;
// }
.tab-pills .nav-link.active path {
    fill: #0078f5 !important;
}
img.dotted_img {
    width: 45px;
    height: 45px;
    left: -22px;
    top: -11px;
    position: relative;
    object-fit: none;
}
.qr-body-actve img.dotted_img {
    width: 40px;
    height: 40px;
    left: -9px;
    top: -8px;
    position: relative;
    object-fit: none;
}
input[type="number"]::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}
   

// manjinder css
@media(max-width:480px){
  
    .listing_fl .name_heading{
        margin-bottom: 5px;
    }
    .listing_fl .name_heading span{
        font-size: 12px;
    }
    .btns_blue {
        gap: 10px;
    }
}