.data-pills .nav-link.active,
.data-pills .show > .nav-link {
  border-bottom: 5px solid #0070fc;
  background-color: transparent !important;
  color: #000 !important;
  border-radius: 0px;
}

.data-pills:before {
  content: "";
  position: absolute;
  top: 58px;
  left: -1px;
  width: 100%;
  height: 1px;
  background-color: #dde2e6;
}

.set-bg-box {
  position: relative;
}
.data-pills .nav-item {
  width: 50%;
}
.data-pills .nav-link {
  color: #000;
}
.data-pills .nav-item .nav-link {
  width: 100%;
}
.data-img {
  width: 20px;
  margin-left: 12px;
}

.light-blub {
  width: 100%;
  max-width: 24px;
  height: 100%;
}

.para-text p {
  font-size: 14px;
}

.para-text a {
  text-decoration: none;
}

ul.ul-link {
  list-style: none;
  background-color: #f4f4f4;
  border-radius: 11px;
  padding: 0px;
  // z-index: 14;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.ul-link .span-no {
  padding: 7px;
  margin-right: 12px;
  width: 100%;
  text-align: center;
  max-width: 37px;
  display: inline-block;
}

.ul-link li {
  z-index: 12;
  position: relative;
  border-radius: 8px;
}

.ul-link a {
  text-decoration: none;
  color: #212121;
}

.code-btn .color-bx {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #1961e4;
}
.code-btn {
  background-color: #f4f4f4;
  padding: 8px;
  border-radius: 8px;
}
.info_detail select {
  background-color: #f4f4f4;
}

.upload-text {
  border: 2px dashed #f4f4f4;
  border-radius: 9px;
  min-height: 216px;
  margin-bottom: 20px;
}

.modal-set .modal-dialog {
  width: 100%;
  max-width: 712px;
}
.set-img {
  width: 100%;
}

.data-body form {
  border: 1px solid #c5c5c5;
  border-radius: 8px;
}

.one {
  padding: 10px;
  border-bottom: 1px solid #c5c5c5;
}
.btn_button-bx .btn-outline-secondary {
  color: #212121;
}
.gradeintblueheight {
  height: unset !important;
}
.back-gradient {
  background: linear-gradient(
    180deg,
    #eaf2fa 0%,
    #eaf2fa 0.01%,
    #9cceff 70.31%,
    #50a6f5 100%
  );
  min-height: 100vh;
}
// .modal-dialog.modal-dialog-centered {
//   margin: auto;
// }
input[type="number"]::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

@media (max-width: 480px) {
  .data-img {
    width: 11px;  
    margin-left: 0;
  }
}
