.scanner-pills .nav-item {
    width: 33.3%;
    max-width: 400px;
}
.scanner-pills .nav-link{
    color: #000;
}
.scanner-pills .nav-item .nav-link{
    width: 100%;
    }
.scanner-pills  .nav-link.active, .scanner-pills .show>.nav-link {
    border-bottom: 5px solid #0070fc;
    background-color: transparent !important;
    color: #000 !important;
    border-radius: 0px;
}


.scanner-pills :before {
    content: '';
    position: absolute;
    top: 58px;
    left: -1px;
    width: 100%;
    height: 1px;
    background-color: #dde2e6;
}  
.upload_div_btn .add_div {
    border: 2px dashed #e3e0e0;
    border-radius: 9px;
    min-height: 266px;
    margin-bottom: 20px;
}
a{
    text-decoration: none;
}
.gradeintblueheight {
height: 100vh;
}

.hightvh100{
    height: 100vh;
}

.scan-detail-dv {         
    
    min-height: 344px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-gradient{
    background: linear-gradient(180deg, #EAF2FA 0%, #EAF2FA 0.01%, #9CCEFF 70.31%, #50A6F5 100%);
    min-height: 100vh;
}

span.fw-bold.ms-3.text-dark.pointer:hover{
    text-decoration: underline;
}
.scan-detail-dv.text-center td {
    padding: 10px !important;
}
