.contact-form {
  background-color: #eaeffc;
  padding: 20px;
  border-radius: 14px;
}
.form-detail h1 {
  font-size: 50px;
  color: #3c62e2;
}
.contact-img {
  width: 100%;
  // max-width: 596px;
  height: 310px;
  object-fit: cover;
  border-radius: 15px;
}
.form-detail {
  // background: #fff;
  padding: 3rem 0rem;
}
