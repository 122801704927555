.accordin-tab {
  padding: 25px 0;
  width: 100%;
  margin: 0px auto;
  max-width: 700px;
}
.faq-page {
  background: linear-gradient(
    180deg,
    #eaf2fa 0%,
    #eaf2fa 0.01%,
    #9cceff 70.31%,
    #50a6f5 100%
  );
  color: #000;
  text-align: center;
  padding: 100px 0;
}

.faq-page h2 {
  font-size: 50px;
}
.accordin-tab .nav-pills {
  margin: 0 0 20px 0;
}
.faq-title p {
  max-width: 600px;
  margin: 0 auto;
  color: #626161;
}
.accordin-tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #1961e4;
}
.accordin-tab .nav-link {
  color: #626161;
}
.accordin-tab .accordion-button:not(.collapsed) {
  background-color: #fff;
}
@media (max-width: 480px) {
  .faq-page h2 {
    font-size: 25px;
  }
}
