@import "./mixins";

// Colors
@import "./colors";
@import "./table";

//  login css start

.set-pass .setfa {
  position: absolute;
  top: 44px;
  right: 12px;
  color: #888;
}

.m_img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.login-bx {
  padding: 32px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #eee;
  max-width: 456px;
  text-align: center;
  margin: 0 auto;
}

.m-sign {
  font-size: 14px;
  color: #3c62e2;
}

.m_login {
  font-family: "Figtree", sans-serif !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.m_login .set-col {
  border: 1px solid #eeeeee;
}

.or-bx:before {
  content: "";
  position: absolute;
  width: 43%;
  height: 1px;
  background-color: #e4e4e4;
  left: 0;
  top: 10px;
  z-index: -1;
}

.or-bx:after {
  content: "";
  position: absolute;
  width: 43%;
  height: 1px;
  background-color: #e4e4e4;
  right: 0;
  top: 10px;
  z-index: -1;
}

.or-bx {
  position: relative;
  z-index: 2;
}

// sign up add
.sign-div {
  padding: 32px;
  max-width: 456px;
}

.h_features h6 {
  color: 212121;
}

a,
.m-sign {
  color: #3c62e2;
}

@media (max-width: 1024px) {
  .track_box br {
    display: none;
  }

  .login-bx {
    padding: 18px;
  }
}

// all login css end

.modal-open .modal {
  z-index: 99999;
}

.sign {
  font-size: 24px !important;

  &.sign1 {
    font-family: "Meddon", cursive;
  }

  &.sign2 {
    font-family: "Mr De Haviland", cursive;
  }

  &.sign3 {
    font-family: "Pacifico", cursive;
  }

  &.sign4 {
    font-family: "Pinyon Script", cursive;
  }
}

.tox-notifications-container,
.tox-statusbar__branding {
  display: none !important;
}

.charttooptip {
  width: max-content;
  max-width: 300px;
}

.perAmSpan {
  margin: 0 !important;

  &:not(:last-child) {
    &::after {
      content: "/";
      display: inline-block;
      margin: 0 5px;
    }
  }
}

.tooptipWraper {
  position: relative;
  overflow: visible;
  cursor: pointer;

  &:hover {
    .tooptipBox {
      display: block;
      border: 1px solid #797878;
      border-radius: 8px;
    }
  }
}

.tooptipBox {
  display: none;
  position: absolute;
  padding: 6px;
  // box-shadow: 10px 0 4px 0px #00000038;
  top: 100%;
  background-color: #fff;
  z-index: 9999;

  &:hover {
    display: none !important;
  }
}

.Toastify__toast-container--top-right {
  z-index: 99999 !important;
}

.btn-sm {
  padding: 5px 12px !important;
  font-size: 14px !important;
}

.btn-vsm {
  padding: 3px 11px !important;
  font-size: 10px !important;
}

.newdrop {
  width: 258px;
}

img.reviewsimg {
  width: 45px;
  height: 45px;
  border-radius: 31px;
}

h3.personname {
  font-size: 15px;
  font-weight: 600;
}

h3.dayscl {
  font-size: 15px;
}

button.replybtn {
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 7px 12px;
  background: #fff;
}

.replybtn:before {
  content: "";
  display: block;
  width: 624px;
  height: 1px;
  background: #000;
  left: 88px;
  top: 90%;
  position: absolute;
}

.totale_review {
  font-size: 20px;
  font-weight: 600;
}

.holidays {
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;

  input {
    margin-right: 10px;
  }

  &.active {
    color: $primaryColor;
  }

  > .bold {
    font-weight: 500;
  }

  // &::before{
  //     content: "";
  //     height: 5px;
  //     width: 5px;
  //     position: absolute;
  //     background-color: #000;
  //     left: 0;
  //     top:8px;
  //     border-radius: 50%;
  // }
}

a:disabled,
.disabled {
  pointer-events: none;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  > span {
    font-size: 13px;
    color: #84818a;
  }
}

.chip {
  background: #7561a2;
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

body {
  font-family: $font1 !important;
  font-size: 14px;
}

.page_main {
  background: #fff;
  margin: 2rem 0rem;
  min-height: calc(100vh - 476px);
}

// QR Pattern Type
button.grid-button-bx1 {
  width: 45px;
  height: 45px;
  background: black;
}

button.grid-button-bx2 {
  width: 45px;
  height: 45px;
  background-color: black;
  border: 0px solid;
  border-radius: 50%;
}

button.grid-button-bx3 {
  width: 45px;
  height: 45px;
  background-color: black;
  border: 0px solid;
  border-radius: 18%;
}

button.grid-button-bx4 {
  width: 45px;
  height: 45px;
  background-color: black;
  border: 0px solid;
  border-radius: 20px 0px 20px 0px;
}

button.grid-button-bx5 {
  width: 45px;
  height: 45px;
  border: 10px solid;
  border-style: dotted;
  position: relative;
}

.one_dot {
  background-color: black;
  width: 10px;
  height: 10px;
  border: 0px solid;
  border-radius: 50%;
  position: absolute;
  left: 7px;
  top: 9px;
}

button.grid-button-bx6 {
  width: 45px;
  height: 45px;
  background-color: black;
  border-radius: 0px 20px 0px 20px;
}

// Color Picker
input#color {
  height: 35px;
  width: 40px;
  padding: 0px;
}

input.form-control {
  width: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
  margin-bottom: 5px;
}

.col_side div span {
  font-size: 14px;
  font-weight: 300;
  color: #4a545e;
  margin-right: -12px;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  padding: 10px !important;
  width: 100% !important;
}

.react-tooltip {
  max-width: 250px;
  word-break: break-all;
  white-space: initial;
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

button.btn.btn-primary.emoji_btn {
  background-color: transparent !important;
  color: #1961e4 !important;
  padding: 7px 10px;
}

i.fa.fa-smile.ms-2 {
  color: #1961e4;
}

// WIFI Qr Code Eye CSS
i.fa.fa-eye-slash.fs-5.position-absolute {
  top: 43px;
  right: 28px;
}

i.fa.fa-eye.fs-5.position-absolute {
  top: 43px;
  right: 28px;
}

// Emoji CSS
div#epr-category-nav-id {
  display: none;
}

.optionContainer {
  border: none !important;
  margin-bottom: 4px;
}

.min-sidebar ~ div > .main-sidebar a.col_side:hover {
  justify-content: center;
  margin-right: 0px !important;
}

.multiSelectContainer li:hover {
  background-color: #d0d5dd !important;
  color: #765aa6 !important;
  border-radius: 8px;
}

.optionListContainer {
  background: #fff;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  border: none;
  border-radius: 14px;
  padding: 0.7rem 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

span.text-uppercase.me-2.h6.currency-format.pointer:hover {
  text-decoration: underline;
}

.star {
  // color: red;
  display: none;
  color: #202020;
}

hr.bold {
  border: 1px solid;
  width: 50%;
  margin: 9px 4px;
}

.action_icons {
  display: flex;
}

p.sign_up.ms-2.pointer.signup_color {
  color: #775da6;
}

a.edit_icon {
  /* border: 1px solid #DCDCDC; */
  height: 20px;
  width: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}

span.edit_icon {
  border: 1px solid #dcdcdc;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-left: 3px;
  cursor: pointer;
}

i.material-icons.edit {
  font-size: 15px;
  /* font-weight: 600; */
  color: #705ee6;
}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}

i.fa.fa-arrow-left {
  color: #000;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #fff !important;
  // text-transform: capitalize;
  // white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.disable {
  cursor: not-allowed !important;
}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 12px;
    top: 50%;
    font-size: 15px;
    transform: translateY(-50%);
    color: #84818a;
  }

  .sufix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818a;
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818a !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a4a8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a4a8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a4a8;
}

main.main {
  background-color: #f4f4f4 !important;
}

.react-datepicker-popper {
  z-index: 99999;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 8px 25px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
}

.alex-text p {
  word-break: break-all;
}

.link_btn {
  color: #3161e4;
  text-decoration: underline;
  word-break: break-all;
}
a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #775da6;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > .container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #e8e8e8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #775da6 !important;
}

button.btn.btn-primary.w-100 {
  display: flex;
  align-items: center;
  justify-content: center;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f6f6f6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f6f6f6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f6f6f6;
}

.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  > input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {
  > li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;
    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }
    }

    &.disabled {
      a {
        border-color: #e8e8e8;
        color: #e8e8e8;
      }
    }
  }
}

.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}

.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}

.grid-box img {
  width: 100%;
  object-fit: contain;
}

p.list_desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-white.rounded-4.p-4.position-sticky.top-0 {
  top: 80px !important;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.table td,
.table th {
  padding: 0px 14px !important;
  vertical-align: middle;
  height: 40px;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}

i.fa.fa-trash {
  color: #c02e2e;
}

.filterFlex {
  white-space: nowrap;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 1rem;
  justify-content: end;
}

.checkPrice {
  column-gap: 10px;

  span {
    display: inline-block;

    input {
      margin-right: 5px;
    }
  }
}

// Responsive Design
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.me-2 {
  white-space: nowrap;
}

.form-control {
  // padding: 21px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;

  &.invalid {
    border-color: red !important;
  }
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}

p.phone_no {
  color: #84818a;
}

.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.me-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}

// profile changes
input.searchbar.me-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}

label.btn.btn-primary.delete.ms-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #775da6 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ms-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 42px;
  border-radius: 10px;
  font-weight: 500;
}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
}

button.btn.btn-primary.edit.ms-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}

.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption:not(:hover) {
  background: initial;
  color: initial;
}

.currencyChart {
  position: relative;

  .textOver {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Responsive css:
@media (max-width: 768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

@media (max-width: 1199px) {
  .vcard_name h2 {
    font-size: 50px !important;
    line-height: 48px !important;
  }
  .bottom-subscribe {
    flex-wrap: wrap;
}
.bar_div {
  width: 100%;
}

  .vcard_name p {
    font-size: 22px !important;
  }
}

@media (max-width: 991px) {
  .banner {
    height: 225px !important;
  }
  .main-blog .big_image {
    height: 300px !important;
  }
  .contact-img {
    margin-bottom: 1rem;
}
  .vcard_name p {
    font-size: 18px !important;
  }
  .vcard_name h2 {
    font-size: 40px !important;
    line-height: 38px !important;
  }
  .banner_back img {
    height: 220px !important;
    width: 230px !important;
    top: 30px !important;
  }
  .layout_vcard .col-lg-12 {
    text-align: right;
  }
  // .main-sidebar {
  //   margin-top: 0px !important;
  // }
  .qr_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main_frame svg {
    height: 350px;
  }
  .row_22.border_22 {
    border-bottom: 0px;
  }
  .sidebar-tabs {
    margin-bottom: 1rem;
  }
  .tab-pills .nav-link {
    min-width: 108px !important;
  }
  .list-group-item .col-md-12.col-lg-6.border-right {
    border-right: 0px !important;
  }
  .box_navbar {
    position: absolute;
    background: #fff;
    right: 15px;
    top: 66px;
    z-index: 9;
    padding: 15px 15px;
    box-shadow: 0px 0px 6px -4px #000;
  }
  .row_22 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    padding: 15px 0px;
    margin-bottom: 15px;
  }
  .modal-dialog.modal-dialog-centered.w-75 {
    width: 440px !important;
    margin: auto;
    padding: 0px 15px;
  }

  .grid-box {
    width: 50px !important;
    height: 50px !important;
  }

  .track_row {
    margin-right: 2rem !important;
  }

  .main-navbar.min-sidebar {
    margin-left: 0px !important;
  }

  .icon_ibtn {
    font-size: 15px;
  }

  .btn {
    font-size: 12px !important;
    padding: 8px 15px;
  }

  .filterFlex {
    justify-content: end;
  }

  .container.mt-5 {
    margin-top: 70px !important;
  }

  .btn_button h1 {
    font-size: 12px;
    height: 40px;
  }

  .btn_button {
    height: 40px;
  }

  .Sales_row p {
    font-size: 14px;
  }

  .Sales_row h2 {
    font-size: 18px;
  }

  span.green_icon .material-icons {
    font-size: 14px;
  }

  .green_icon {
    font-size: 10px;
  }

  span.text_color {
    font-size: 10px;
    color: #6b7280;
  }
}

@media (max-width: 499px) {
  .progress_row {
    margin-left: 2.3rem;
  }
  .contact-img {
    height: 220px !important;
  }
  .qr_ico {
    width: 100%;
    margin-bottom: 1rem;
  }
  .qr_img {
    padding-left: 0rem !important;
    margin-left: 0px !important;
    text-align: center;
  }
  .list_data {
    justify-content: space-between;
  }
  .rate_img {
    right: -10px !important;
    top: -34px !important;
    text-align: center;
  }
  .rate_img h3 {
    font-size: 12px !important;
  }
  .rate_img img {
    width: 18px !important;
  }
  .row_qrcode {
    flex-wrap: wrap;
  }
  .qr_img {
    width: 100%;
    border-left: 0px !important;
  }
  button.color_box {
    height: 30px !important;
    width: 30px !important;
  }

  .form-check.d-flex {
    flex-wrap: wrap;
  }

  .form-check.d-flex .form-check-label {
    width: 100%;
    margin: 0px !important;
  }

  .row_images {
    width: 70px !important;
  }

  .progress_list {
    flex-wrap: wrap;
  }

  .review_text h1 {
    flex-wrap: wrap;
  }

  img.wallets_img2 {
    width: 30px;
    height: 30px;
  }

  h1.layout_heading {
    font-size: 16px;
  }

  .progress_list {
    padding-top: 0.5rem;
  }
}

/////////////############ Sales ############////////////
.textOver h3 {
  font-size: 10px;
}

.new_div {
  display: flex;
  justify-content: end;
}

.download_icon {
  border-radius: 8px;
  background: #f1f0fc !important;
  color: #6956e5 !important;
  border: 1px solid #d0d5dd !important;
  padding: 8px 15px;
}

.download_icon i {
  color: #6956e5 !important;
}

.download_icon2 {
  border-radius: 8px;
  background: #e9fbf6 !important;
  color: #047857 !important;
  border: 1px solid #d0d5dd !important;
  padding: 8px 15px;
}

.download_icon2 i {
  color: #047857 !important;
}

.download_icon2:hover {
  background: #e9fbf6 !important;
  border: 1px solid #d0d5dd !important;
}

.btn_color {
  background: #fff;
  border: 1px solid #d7dbe2;
  border-radius: 8px !important;
  margin-right: 1px;
  color: #111827;
  font-weight: 500;
}

.btn_color:hover {
  background: #fff;
}

.main-btn {
  background: #f3f3f3;
}

.labelbtn {
  background: #ffffff00;
  color: #6b7280;
}

.labelbtn:hover {
  background: #ffffff00 !important;
  border: 1px solid #f8f9fa;
}

.btn_btn {
  display: inline-flex;
  justify-content: center;
  color: #344054;
}

input.inputdesign {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  background: #fff;
  padding: 8px 14px;
}

.parimarybtn span {
  color: #7e858e;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.sales_layout {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #fff;
  align-items: center;
}

.list_box {
  display: flex;
  align-items: center;
}

.list span {
  color: #6956e5;
  background: #f4f5f6;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
}

.list_box h1 {
  font-size: 16px;
  margin-bottom: 5px;
}

.list_box {
  display: flex;
  align-items: center;
}

.list {
  margin: 0px 10px;
  margin-right: 20px;
  position: relative;
}

.list_boxRight h2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.list_box p {
  font-size: 14px;
  color: #484848;
  margin-bottom: 0px;
}

.list_boxRight span {
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
}

.red_icon {
  background: #e9253133;
  color: #e92531;
  padding: 5px 5px;
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
}

.green_icon {
  background: #04785714;
  color: #047857 !important;
  padding: 5px 5px;
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
}

.red_text {
  color: #fc0000 !important;
}

.list_boxRight p {
  font-size: 14px;
  color: #202020;
}

p.msg_list {
  font-size: 9px;
  background: #ffea8a;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 50px;
  position: absolute;
  margin-bottom: 0px;
  top: -5px;
  right: 8px;
  border: 1px solid #fff;
}

.layout {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

h1.layout_heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #3c3e49;
  padding: 0px;
}

.right_order {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

.right_layout {
  display: flex;
  justify-content: space-between;
}

.right_order h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px;
}

.right_order p {
  font-size: 14px;
  color: #696974;
  margin-bottom: 0px;
}

.right_order span {
  font-size: 12px;
}

.order {
  width: 60%;
  margin-left: auto;
}

.box_border {
  margin-top: 1rem;
  border-top: 1px solid #f1f1f5;
  padding-top: 1rem;
}

.box_border {
  margin-top: 1rem;
  border-top: 1px solid #f1f1f5;
  padding-top: 1rem;
}

.header_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.parimarybtn {
  border-radius: 8px;
  background: #fff !important;
  color: #202020 !important;
  border: 1px solid #d0d5dd !important;
  padding: 8px 15px;
}

.parimarybtn2 {
  border-radius: 8px;
  background: #fff !important;
  color: #6f3bce !important;
  // border: 1px solid #D0D5DD !important;
  border: none;
  padding: 8px 15px;
}

.parimarybtn span {
  font-size: 15px;
  margin-right: 4px;
}

.data_row:not(tr) {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.list_row p {
  font-size: 12px;
  color: #667085;
  margin-bottom: 4px;
}

.list_row h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .list_row {
//   width: 19%;

// }

.progress_list {
  display: flex;
  align-items: center;
}

.progress_row {
  width: 100%;
  margin: 0px 5px;
  background: none;
  border-radius: 0px;
  height: 24px;
  position: relative;
}

.line_row {
  display: flex;
  align-items: center;
}

.line_row2 {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.line_row p {
  margin-bottom: 0px;
  color: #484848;
  font-size: 16px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line_row2 p {
  margin-bottom: 0px;
  color: #484848;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.progress_list {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
}

.progress-bar.progress_list {
  background: #b2b3b4;
  border-radius: 4px;
  position: relative;
}

.footer_chart a {
  font-size: 16px;
  color: #6956e5;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  margin-bottom: 0px;
  border-top: 1px solid #eaecf0;
  margin-top: 1rem;
  padding-top: 1rem;
}

.footer_chart a:hover {
  color: $primaryColor !important;
  text-decoration: none;
}

.footer_chart span {
  margin-left: 5px;
  font-size: 14px;
}

.parimarybtn span {
  color: #898989;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.btn_2 .btn {
  padding: 10px 15px !important;
  border-radius: 6px;
  background: #fff;
  color: #344054 !important;
  border: 1px solid #d0d5dd;
}

label.btn:hover {
  color: #344054;
}

.Countr_text h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #3c3e49;
  margin-right: 10px;
}

.Countr_text p {
  font-size: 14px;
  color: #696974;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.left_design {
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }

  .text-truncate {
    width: 110px;
  }
}

.Countr_text {
  margin-top: 1rem;
}

.Countr_text p {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 0px;
}

.design {
  margin-top: 2rem;
}

.left_design {
  margin-bottom: 1rem;
}

.left_design p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.green_text {
  background: none;
}

.red_text {
  background: none;
}

p.bold_text {
  color: #6b7280 !important;
  font-weight: 600 !important;
}

.border_line {
  padding-right: 10px;
}

.box_round {
  width: 10px;
  height: 10px;
  background: $primaryColor;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round2 {
  width: 10px;
  height: 10px;
  background: #ff974a;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round3 {
  width: 10px;
  height: 10px;
  background: #ffc542;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round4 {
  width: 10px;
  height: 10px;
  background: #cacaca;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round5 {
  width: 10px;
  height: 10px;
  background: #e7e5e5;
  border-radius: 10px;
  margin-right: 10px;
}

span.progress_value {
  color: #3c3e49;
  font-size: 14px;
  text-align: right;
  margin-left: 5px;
  position: absolute;
  top: 2px;
  left: 5px;
}

span.progress_value2 {
  color: #3c3e49;
  font-size: 14px;
  text-align: right;
  margin-left: 5px;
  position: absolute;
  top: 2px;
  right: 5px;
}

.border_line {
  border: 1px solid #f1f1f1;
  height: 26px;
  border-radius: 6px;
}

.line_row img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 50%;
}

.line_row2 img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 4px;
}

.switch_btn {
  min-height: unset;
  margin-left: 20px;
}

.switch_check {
  width: 36px !important;
  height: 20px;
  top: -4px;
}

.flag_div img {
  width: 28px;
  height: 20px;
  margin-left: 5px;
}

.flag_div {
  display: flex;
  align-items: center;
}

tr.data_row {
  height: 50px;
}

/////////////############ Marketing ############////////////
.filterFlex2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1.text_heading {
  font-size: 24px;
}

.sales_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Sales_box h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}

.sales_flex p {
  margin-bottom: 0;
  color: #484848;
}

.slaes_flex p {
  color: #667085;
  margin-bottom: 0px;
}

// .sales_flex span {
//   font-size: 14px;
//   color: #fff;
//   border-radius: 2px;
//   margin-left: 5px;
// }
.Sales_box {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 15px;
}

.Sales_row {
  // border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 15px;
  background: #fff;
}

.layout_box {
  display: flex;
  justify-content: space-between;
}

.Sales_box {
  width: 19%;
  background: #fff;
}

.btn_flex {
  display: flex;
}

span.icon_i {
  border: 1px solid;
  padding: 0px 5px;
  font-size: 13px;
  border-radius: 50%;
  color: #84818a;
  position: relative;
  top: -2px;
  margin-left: 4px;
}

.btn i {
  color: #484848;
}

/////////////############ Dashboard ############////////////
.btn_button {
  display: flex;
}

.download_icon:hover {
  background: #f1f0fc !important;
}

.btn_button h1 {
  margin-bottom: 0px;
  font-size: 16px;
  height: 42px;
  padding: 12px 20px;
  color: #6b7280;
  cursor: pointer;
}

.btn_button {
  background: #a4acb914;
  height: 42px;
  border-radius: 8px;
}

.tab_btn2 {
  color: #111827 !important;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
}

tr.data_row th {
  color: #6b7280;
}

tr.data_row td {
  color: #6b7280;
}

.header_list p {
  font-size: 12px;
  color: #6b7280;
}

.review_text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.id_text {
  width: 80%;
}

.review_main {
  display: flex;
  align-items: center;
}

.icons_div p {
  // margin-left: 10px;
  margin-bottom: 0px;
  color: #6b7280;
  font-size: 12px;
}

.review_main h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #3c3e49;
}

.Sales_row p {
  font-size: 16px;
  color: #111827;
  margin-bottom: 10px;
}

.Sales_row h2 {
  font-size: 20px;
  font-weight: 600;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.className_box {
  display: flex;
  justify-content: space-between;
}

span.text_color {
  font-size: 12px;
  color: #6b7280;
}

span.red_icon .material-icons {
  font-size: 17px;
  position: relative;
  top: 4px;
}

span.green_icon .material-icons {
  font-size: 17px;
  position: relative;
  top: 4px;
  color: #047857;
}

.table td {
  border: 1px solid #f3f3f3;
}

.table th {
  border: 1px solid #f3f3f3;
}

.dashboard_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  margin-top: 75px;
}

.icon_ibtn {
  color: #fff !important;
  font-size: 19px;
  position: relative;
  top: 2px;
}

span.drow_text {
  position: relative;
  top: 0;
  font-size: 16px;
  color: #4b5563;
}

.icon_ibtn2 {
  color: #6f3bce !important;
  font-size: 19px;
  position: relative;
  top: 2px;
}

.dashboard_row h1 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #3c3e49;
}

.dashboard_row p {
  color: #98a2b3;
  font-size: 14px;
  margin-bottom: 0px;
}

.data h1 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #3c3e49;
}

.border-0 {
  border: 1px solid #eaecf0 !important;
  width: fit-content;
  margin-top: 0px !important;
  padding: 4px 10px !important;
  font-size: 16px !important;
  border-radius: 8px;
  color: #4b5563 !important;
}

.data p {
  color: #3c3e49;
  font-size: 16px;
  margin-bottom: 1.5rem;
}

.data {
  border: 1px solid #f3f4f5;
  border-radius: 4px;
  padding: 20px;
}

.orders_layout {
  display: flex;
  justify-content: space-between;
  background: #faf9ff;
  padding: 15px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

// .value_orders {
//   width: 19%;
// }
.value_orders p {
  font-size: 12px;
  color: #3c3e49;
  margin-bottom: 10px;
}

.value_orders h1 {
  font-size: 18px;
  font-weight: 600;
  color: #3c3e49;
  margin-bottom: 0px;
}

span.span_text {
  font-weight: 100;
  margin-left: 10px;
}

img.wallets_img2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.review_layout {
  border-bottom: 1px solid #f2f3f5;
  padding-bottom: 1rem;
}

.review_layout {
  display: flex;
}

.review_text h1 {
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  // align-items: center;
  margin-left: 10px;
  color: #3c3e49;
}

span.time_text {
  font-size: 12px;
  color: #7e858e;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

span.icon_span span {
  font-size: 20px;
  color: #fcbe72;
}

.review_text p {
  margin-left: 10px;
  margin-bottom: 0px;
  color: #7e858e;
  font-size: 12px;
}

img.wallets_img3 {
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border-radius: 50%;
}

.table thead th {
  vertical-align: middle !important;
}

a.border_chart {
  border: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.parimarybtn:hover {
  background: #fff !important;
  color: #000 !important;
}

.parimarybtn:focus {
  box-shadow: none !important;
}

/////////////############ Scroll CSS ############////////////

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(139, 139, 139);
}

/////////////############ Scroll CSS End ############////////////
.sidees {
  display: flex;
  align-items: center;
}

.list-format {
  flex-wrap: wrap;
}

p.text_p {
  margin-bottom: 0px;
  color: #75757a;
}

.inputOverlapIcon {
  z-index: 9999;
}

.googleChart text {
  font-size: 12px;
  color: #111827;
}

.profile_sales img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #efeffc;
  margin-right: 1rem;
}

.sale_details {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.list_detail {
  display: flex;
  align-items: center;
}

.sale_details h1 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #111827;
  font-weight: 600;
}

h3.Viewtext {
  font-size: 24px;
  font-weight: 600;
}

h4.company_heading {
  margin-bottom: 10px;
  font-size: 16px;
  color: #111827;
}

h4.company_name i {
  margin-right: 5px;
  color: #fcbe72 !important;
}

.light_btn {
  background: #fff !important;
  color: #212121 !important;
  border: 1px solid #dee2e6;
  height: 44px;
}

.light_btn:focus {
  box-shadow: none;
}

.flex_info {
  display: flex;
  justify-content: space-between;
}

.flex_info p {
  width: 200px;
  margin-right: 2rem;
  color: #6b7280;
}

.box_row {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.dflex_div {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin-left: auto;
}

.dflex_div p {
  margin-bottom: 10px;
  color: #6b7280;
  font-size: 16px;
}

p.dflex_text {
  color: #3c3e49;
}

p.dflex_text2 {
  color: #705ee6;
}

img.flag_img {
  width: 28px;
  height: 20px;
  margin-right: 10px;
}

img.items_img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

p.back_text {
  color: #7e858e;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

p.back_text i {
  color: #171f46;
}

.data_layout {
  height: 342px;
}

.chart_layout.mb-3 {
  height: 535px;
}

.right_value {
  padding: 1.2rem 0px;
}

.chart_2 {
  height: 347px;
}

.height_grafh {
  height: 342px;
}

.height_chart {
  height: 494px;
}

.footer_chart2 {
  padding-top: 1rem;
  border-top: 1px solid #f7f7f7;
  margin-top: 1rem;
}

.layout_hight {
  height: 250px;
  overflow: auto;
  padding: 0px 5px;
}

.list_text {
  display: flex;
  margin-top: 1.6rem;
}

.list_text span {
  margin-right: 15px;
}

.list_text span {
  margin-right: 15px;
  font-size: 20px;
  color: #0e9f6e;
  background: #f3faf7;
  padding: 2px 2px;
  border-radius: 50%;
}

.list_text p {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.list_text2 span {
  color: #fcbe72;
  background: #fff6ea;
  padding: 2px 2px;
  border-radius: 50%;
}

span.start_row span {
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.color_start {
  color: #fcbe72;
}

.removeBg {
  height: 37px;
}

.css-13cymwt-control {
  border-radius: 8px !important;
}

.detail_data .material-icons {
  font-size: 18px;
  color: #98a2b3 !important;
  margin-right: 5px;
  margin-left: 0px !important;
}

.detail_data span {
  margin: 0px 5px;
}

img.img_slider {
  height: 170px;
  object-fit: cover;
  border-radius: 4px;
}

ol.carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

ol.carousel-indicators {
  margin-bottom: 0px;
}

.description p {
  font-size: 12px;
  color: #3c3e49;
  line-height: 22px;
}

span.price_deta {
  font-size: 12px;
  color: #3c3e49;
}

p.general .material-icons {
  margin-right: 10px;
}

a.btn.btn-primary:hover {
  color: #fff !important;
}

.review_main2 {
  display: flex;
  align-items: center;
  background: #f8f9fb;
  padding: 15px;
  margin-bottom: 2rem;
}

.review_main2 h2 {
  font-size: 24px;
  font-weight: 600;
}

.heading_details .material-icons {
  color: #3c3e49;
  margin-right: 15px;
}

.icons_div h3 {
  font-size: 14px;
  font-weight: 600;
}

h1.location_heading {
  font-size: 14px;
  font-weight: 600;
  color: #3c3e49;
  padding: 0px 15px;
}

.location_layout {
  display: flex;
  padding: 0px 15px;
}

.location_layout .material-icons {
  color: #6956e5;
  font-size: 16px;
  width: 35px;
  height: 35px;
  background: #6956e514;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.location_layout {
  display: flex;
  padding: 12px 15px;
  border-top: 1px solid #eaecf0;
}

.location_layout p {
  font-size: 12px;
  color: #3c3e49;
  max-width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

img.money_bag {
  width: 24px;
  margin-right: 10px;
}

.border_heading {
  padding: 0px !important;
  border-bottom: 0px !important;
}

.green_color {
  color: #0aa630 !important;
}

.box_details {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-left: 4px solid #b9eb8e;
}

.box_details2 {
  border-left: 4px solid #7a74ec;
}

.box_details3 {
  border-left: 4px solid #fcbe72;
}

.tab_icons .material-icons {
  font-size: 18px;
  border: 1px solid #eaecf0;
  padding: 4px 4px;
  border-radius: 4px;
  cursor: pointer;
}

.color1 {
  color: #6956e5;
}

.color2 {
  color: #6b7280;
}

tr.data_row h1 {
  font-size: 14px;
  color: #3c3e49;
  margin-bottom: 0px;
}

tr.data_row p {
  margin-bottom: 0px;
  color: #6b7280;
  font-size: 12px;
}

td.vertical {
  vertical-align: unset;
}

td.vertical {
  padding: 10px 10px !important;
}

button.btn_table {
  color: #0e9f6e;
  background: #04785714;
  border-radius: 4px;
  border: none;

  &.danger {
    color: red;
    background-color: #ffe2e2;
  }
}

span.spanBtn_1 {
  padding: 2px 15px;
  background: #0e9f6e24;
  color: #0e9f6e !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

span.spanBtn_2 {
  padding: 2px 15px;
  background: #00aaff24 !important;
  color: #00aaff !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

span.spanBtn_3 {
  padding: 2px 15px;
  background: #feb77424 !important;
  color: #feb774 !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

h4.heading_4 {
  color: #3c3e49;
  font-size: 12px;
  margin-bottom: 1rem;
}

table.table_price > tbody > tr > td:nth-of-type(1) {
  color: #705ee6;
}

form.search_box .material-icons {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #717275;
  font-size: 22px;
}

form.search_box {
  position: relative;
}

form.search_box input {
  padding-left: 28px;
}

.star-icon {
  color: #fcbe72;
}

.replied {
  padding: 4px 15px;
  background: #0e9f6e24;
  color: #0e9f6e !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

.not_replied {
  padding: 4px 15px;
  background: #feb77424 !important;
  color: #fd9210 !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

.company_discription {
  width: 100%;
}

a.btn.btn-primary.profiles:hover {
  color: #7a7a7a !important;
}

.m_modal {
  width: 100%;
}

.media {
  align-items: center;
}

.login_img {
  text-align: center;
}

img.logos {
  width: 200px;
  margin-bottom: 2rem;
}

.width_btn {
  border: 1px solid #e9e9e9;
  background: #eaecf0;
}

.company_info {
  display: flex;
  align-items: center;
}

.description_edit p {
  border: 1px solid #eaecf0;
  padding: 7px 15px;
  min-height: 37px;
  border-radius: 8px;
  width: 100%;
}

.text_logo h3 {
  font-size: 16px;
  margin-bottom: 1rem;
  margin-right: 3rem;
  color: #484848;
}

.sidenv {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-navbar.min-sidebar {
  width: calc(100% - 78px);
}

.dropdown-menu.show {
  display: block;
  z-index: 99999 !important;
  width: max-content;
}

.material-icons {
  font-size: 20px;
}

.data_layout {
  height: 342px;
  overflow: auto;
  padding: 0px 5px;
}

.block_div {
  display: none;
}

table th {
  white-space: unset !important;
}

span.list_product {
  white-space: break-spaces;
  width: 90px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // -webkit-line-clamp: 2;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}

.css-13cymwt-control {
  min-width: 150px;
  min-height: 38px !important;
}

.add_content {
  display: flex;
  background: #fff !important;
  color: #775da6 !important;
}

.add_content:hover {
  display: flex;
  background: #fff !important;
  color: #775da6 !important;
}

//////////##### Marketing New Pgae css ///////////#####

.marketbox {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ececec;
  padding: 15px;
}

button.btn_online {
  background: #dde9ff;
  border: 1px solid #b8d1ff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  padding: 4px 10px;
  color: #1d2433;
}

button.btn_online span {
  font-size: 12px;
  color: #397cf6;
}
.progress.progress_bar {
  width: 100%;
}
.number_progress {
  display: flex;
  align-items: center;
}

span.span-number {
  width: 25px;
  margin-right: 10px;
}
.box_textbox h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.rowline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowline img {
  width: 50px;
  height: 27px;
}

.box_textbox {
  margin-top: 1.5rem;
}

.rowline p {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 0px;
}

span.red_text1 {
  color: #e0173c;
  margin-right: 10px;
}

//////////##### Marketing New Pgae css end ///////////#####

//////////##### Modal Google Analytics ///////////#####

.modal_dialogdiv {
  max-width: 900px;
  background: #d6f2ff;
}

.modal_contentdiv {
  margin: 4rem;
  border-radius: 8px;
  border: none;
  padding: 10px;
}

.heading_modal h5 {
  font-size: 32px;
  color: #515151;
  margin-bottom: 0px;
}

.heading_modal img {
  width: 60px;
  height: 60px;
  margin-right: 1rem;
}

.heading_modal {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.skybtn {
  background: #21bbfa !important;
  border: 1px solid #21bbfa !important;
  font-weight: 600;
}

.modal_google label {
  font-size: 20px;
  color: #515151;
}

.lable_plus {
  display: flex;
  width: 100%;
}

.lable_plus i {
  background: #22bbfa;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.min-sidebar ~ div > .main-sidebar i.sidenv {
  display: flex !important;
}

p.view_more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #1961e4;
  margin-bottom: 4px;
}

button.color_box {
  border: 3px solid #848484;
  height: 35px;
  width: 40px;
}

.dropdown.dropup {
  top: auto;
  bottom: 100%;
}

// /#############/////////////////

.rowDiv h6 {
  margin-right: 2rem;
}

.rowDiv .gradient-link.gradient-active {
  margin-right: 10px;
}

.gradient-div {
  display: flex;
}

.rowDiv {
  display: flex;
  width: 49%;
}

.rowDiv .gradient_code-btn {
  width: 50%;
}

.rowDiv img.campare_arrow {
  margin: 0px 5px;
}

.box-link.link-active {
  width: fit-content;
}

.box-link img {
  width: 54px !important;
}

.border_top {
  border-top: 1px solid #e0e0e0;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.search_trash_div {
  position: relative;
}

.search_icon {
  position: absolute;
  top: 13px;
  right: 10px;
  color: #898989;
}

.switch_btnline p {
  margin-bottom: 0px;
}

.switch_btnline {
  padding-left: 0px;
}

.dotsbtn {
  font-size: 14px;
}

.switch_btnline input {
  right: 0px;
  width: 40px !important;
  height: 20px !important;
  top: -4px;
}

.switch_btnline {
  padding-left: 0px;
  display: flex;
  padding-right: 0px;
  position: relative;
  justify-content: space-between;
}
.div_download .btn {
  padding: 2px 10px;
}
.div_download .btn:hover {
  background: #1961e4 !important;
  a{
    color: #fff !important;
    span{
      color: #fff !important;
    }
  }
}
.header_tabs {
  display: flex;
}

.select_input {
  width: 160;
  position: relative;
  display: flex;
  align-items: center;
}

.select_url {
  height: 42px;
}

.select_input select {
  font-size: 14px;
  border-left: 0px;
  border-radius: 0px 8px 7px 0px;
  color: #3c3c3c;
  font-weight: 400;
  width: 100px;
}

.select_input select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
  border-left: none;
}

p.type_text {
   background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 8px 0 0 8px;
    border-right: 0;
    color: #202020;
    display: flex;
    flex-wrap: nowrap;
    font-size: 15px;
    height: 44px;
    margin-bottom: 0;
    padding: 10px 0 10px 10px;
    white-space: nowrap;
}
.dropdown-toggle::after {
  vertical-align: .155em !important;
}

.section_background {
  background: #fff;
  padding: 1rem;
  border-radius: 17px;
  position: sticky;
  top: 80px;
}

.qr-body-actve {
  border: 3px solid #3c62e2 !important;
}

.section_background h1 {
  font-size: 14px;
  font-weight: 600;
}

.section_background label {
  font-size: 13px;
  // margin-bottom: 5px;
  font-weight: 600;
}

.border_layout {
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}

.border_layout2 {
  border: 2px solid #3161e4;
  cursor: pointer;
}

.border_layout:hover {
  border: 2px solid #3161e4;
  cursor: pointer;
}

.border_layout .form-check-input {
  width: 18px;
  height: 18px;
  position: relative;
  top: -4px;
  margin-right: 10px;
}

.form-check .form-check-label {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.border_layout .form-check {
  margin-bottom: 15px;
}

.border_layout p {
  display: flex;
}

.border_layout .material-icons {
  font-size: 15px;
  margin-right: 10px;
}

.border_layout p {
  display: flex;
  font-size: 12px;
  margin-bottom: 10px;
}

.done_green {
  color: #05df05;
}

.close_red {
  color: red;
}

.header_tabs {
  display: flex;
}

.btns_tabs button {
  display: flex;
  align-items: center;
}

.btns_tabs {
  display: flex;
  justify-content: end;
}

.light_btn {
  background: #fff;
}

img.img_qr {
  width: 100%;
  height: 300px;
}

.header_tabs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pills_tabs {
  background: #fff;
  margin-right: 18px;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
}

.pills_tabs button {
  position: relative;
  margin-right: 25px;
}

.nav_link.active {
  background: #fff !important;
  color: #000 !important;
}

img.arrow_img {
  position: absolute;
  width: 26px;
  height: 42px;
  object-fit: contain;
  top: 0px;
  right: -21px;
}

.nav_link {
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 42px;
  color: #777676;
}

span.number_btn {
  font-size: 12px;
  width: 25px !important;
  height: 25px !important;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.nav_link.active .number_btn {
  background: #3161e4;
  color: #fff;
}

.number_btn {
  color: #777676;
}

span.font_style {
  font-weight: 100;
}

h1.heading_row {
  margin-bottom: 1.5rem;
  font-size: 15px;
  font-weight: 600;
}

h3.new_folder {
  font-size: 14px;
  color: #3161e4;
  display: flex;
  align-items: center;
  height: 44px;
  margin-bottom: 0px;
}

.file_icon {
  font-size: 25px;
  margin-left: 8px;
}

.row_form {
  margin-top: 1rem;
  border-top: 1px solid #dcdcdc;
  padding-top: 1.5rem;
}

.form-select {
  height: 44px;
  font-size: 14px;
}

.switch_button {
  display: flex;
  align-items: center;
  min-height: 44px;
}

.switch_button input {
  width: 43px !important;
  height: 22px !important;
  top: 6px;
}

.switch_button label {
  margin-left: 14px;
}

.check_box {
  width: 20px;
  height: 20px;
  top: -5px;
}

.check_flex label {
  margin-left: 10px;
}

span.btn_sm {
  font-weight: 600;
  background: #ddd;
  padding: 4px 12px;
  border-radius: 25px;
  border: 1px solid #b5b5b5;
  margin-right: 10px;
}

.label_text {
  font-weight: 400 !important;
}

.password_div {
  position: relative;
}

.eye_view {
  position: absolute;
  right: 13px;
  top: 13px;
  color: #bfbfbf;
  cursor: pointer;
}

.eye_off {
  position: absolute;
  right: 13px;
  top: 9px;
  color: #bfbfbf;
  cursor: pointer;
}

.dropdown_arrow {
  display: flex;
  justify-content: space-between;
}

.arrow_down {
  font-size: 25px;
  position: relative;
  top: -6px;
  color: #3161e4;
}

.p_sec {
  font-weight: 600;
}

// /############/////////////////

canvas {
  width: 100% !important;
}

.w-57 {
  width: 57%;
}

span.number_check {
  font-size: 12px;
  width: 25px !important;
  height: 25px !important;
  border: 2px solid #40d67e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding-top: 3px;
}

i.fa.fa-check.fa-green {
  color: #40d67e;
}

.first_div {
  background-color: #ffffff;
  padding: 10px 15px 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -4px;
}

.second_div {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
}

.primary_btn {
  color: #3161e4;
  border: 1px solid #3161e4;
  font-size: 14px;
  font-weight: 600;
}

.primary_btn:hover {
  background: #eff3ff;
  border: 1px solid #3161e4;
  color: #3161e4 !important;
}

button.btn.danger_btn {
  color: red;
  border: 1px solid red;
}

.danger_btn:hover {
  background: #ffecec;
}

.trash_div {
  display: flex;
  justify-content: space-between;
}

.select_dropdown {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 15px;
}

.dropdown_flex {
  display: flex;
  gap: 10px;
}

.search_trash_div {
  width: 40%;
}

.filters_row {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  border-bottom: 1px solid #dee2e6;
  padding: 15px 15px;
  align-items: center;
}

.row_name {
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
}

.filters_row h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}

.text_filter {
  display: flex;
}

.text_filter h3 {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #3c3c3c;
  margin-bottom: 0px;
  cursor: pointer;
}

h3.bule_h3 {
  color: #3161e4;
}

.primary_btn {
  display: flex;
  align-items: center;
}

.primary_btn2 {
  background: #3161e4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary_btn2:hover {
  background: #3161e4;
  color: #fff;
}

.danger_btn {
  display: flex;
  align-items: center;
}

.btn_restore {
  display: flex;
}

h1.name_heading {
  font-size: 18px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

h1.name_heading input {
  position: relative;
  top: -1px;
}

.btns_blue {
  display: flex;
  flex-wrap: wrap;
}

li.list-group-item {
  align-items: center;
}

.list_rowqr {
  display: flex;
  flex-wrap: wrap;
}

.list_rowqr .layout_qr {
  margin-right: 2rem;
}

.layout_qr {
  display: flex;
  align-items: center;
  word-break: break-all;
}

.ico.me-2 {
  color: #898989;
  font-size: 22px;
}

.row_22 {
  display: flex;
  justify-content: space-between;
}

.border_leftclass {
  padding-left: 1rem;
  border-left: 1px solid #dfdfdf;
  width: 141px;
  margin-left: 15px;
}

.right_list img {
  width: 145px;
  height: 135px;
  object-fit: contain;
  border-left: 1px solid #dfdfdf;
  padding-left: 0.5rem;
}

.layout_qr b {
  margin-left: 5px;
}

.trash_div {
  padding: 15px;
  border-bottom: 1px solid #dfdfdf;
  gap: 10px;
}

.list1 h1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.list1 {
  background: #fff;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
}

.box_color h3 {
  font-size: 15px;
  font-weight: 600;
  color: #ff8400;
}

.box_color p {
  font-size: 12px;
  color: #212121;
  margin-bottom: 0pc;
}

.box_col {
  background: #fff3e6;
  padding: 10px;
  border-radius: 5px;
}

.box_color {
  display: flex;
  justify-content: space-between;
}

.box_col {
  background: #fff3e6;
  padding: 10px;
  border-radius: 5px;
  width: 31%;
}

.box_color2 .box_col {
  background: #e8effc;
}

.box_color2 h3 {
  color: #1961e4;
}

.box_color3 .box_col {
  background: #e6faee;
}

.box_color3 h3 {
  color: #00c853;
}

span.table_th {
  white-space: nowrap;
}

.div_active p {
  margin-bottom: 0px;
  background: #e6faee;
  color: #00c853;
  padding: 2px 14px;
  border-radius: 4px;
}

.div_active .fa-pen {
  margin: 0px 15px;
  font-size: 16px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_bar {
  font-size: 25px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3161e4;
}

span.table_th {
  display: flex;
}

span.table_td {
  display: flex;
}

span.table_th input {
  width: 20px;
  height: 20px;
  top: -4px;
}

span.table_td input {
  width: 20px;
  height: 20px;
  top: -4px;
}

span.table_th .form-check {
  margin-right: 10px !important;
}

span.table_td .form-check {
  margin-right: 10px !important;
}

.select_url2 {
  width: 140px;
}

.tabs_icon {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 8px;
}

.tabs_icon .material-icons {
  font-size: 26px;
  color: #898989;
  padding: 8px;
  cursor: pointer;
  border: 1px solid #edf6ff;
}

.tabs_icon.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3161e4;
  background-color: #edf6ff;
  border: 1px solid #3161e4;
}

.fade:not(.show) {
  opacity: 0;
  display: none;
}

.box_col2 {
  background: #fff3e6;
  padding: 10px;
  border-radius: 5px;
}

.box_col3 {
  background: #e8effc;
}

.box_col2 h3 {
  font-size: 15px;
  font-weight: 600;
  color: #ff8400;
}

.box_col3 h3 {
  color: #1961e4;
}

.box_col2 p {
  font-size: 12px;
  color: #212121;
  margin-bottom: 0pc;
}

.list_data {
  display: flex;
}

.list_data p {
  margin-bottom: 0px;
  display: flex;
  color: #212121;
}

p.p_row1 {
  width: 130px;
  color: #212121;
}

p.p_row12 {
  color: #3161e4;
}

.row_qrcode {
  display: flex;
  justify-content: space-between;
}

.qr_img img {
  width: 125px !important;
  height: 125px !important;
  object-fit: contain;
}

.qr_img {
  padding-left: 1rem;
  border-left: 1px solid #dfdfdf;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.card_row {
  padding: 0px 15px;
  padding-bottom: 15px;
}

.list_slide {
  display: flex;
}

.box_images {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.box_images img {
  width: 190px;
  height: 190px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #cacaca;
  padding: 10px;
  margin-bottom: 14px;
  margin-right: 14px;
}

.box_images2 {
  border: 3px solid #0069de !important;
}

.list_slide p {
  margin-bottom: 0px;
  margin-right: 10px;
  width: 85px;
  color: #212121;
}

.switch_class label {
  color: #3161e4;
  font-weight: 600;
}

.switch_class input {
  position: relative;
  top: -4px;
  width: 35px !important;
  margin-right: 11px;
}

.switch_class {
  display: flex;
}

.more_horizIcon.ms-2 {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #3161e4;
  cursor: pointer;
}

.more_horizIcon.dropdown-toggle:after {
  display: none;
}

.active_h1 {
  display: flex;
  align-items: center;
}

p.active_new {
  margin-bottom: 0px;
  background: #e6faee;
  color: #00c853;
  padding: 2px 10px;
  margin-right: 5px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}

.chart_div {
  height: 320px;
  background: #f4f4f4;
  border-radius: 8px;
}

.select_layout {
  width: fit-content;
  border: 1px solid #ced4da;
}

.primary_btn.dropdown-toggle:after {
  display: none;
}

a.dropdown-item {
  display: flex;
  align-items: center;
}

.drop_icon {
  color: #3161e4;
  font-size: 22px;
}

.home_detail {
  background: #fff;
}

.home_detail {
  background: #fff;
  padding: 6rem 0rem;
  padding-bottom: 0px;
}

.sections_1 h1 {
  text-align: center;
  margin-bottom: 4rem;
}

.main_heading {
  font-size: 32px;
  font-weight: 600;
}

.image_rowdiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.image_rowdiv img {
  object-fit: contain;
  height: 35px;
  width: 100px;
}

.row_images {
  object-fit: contain;
  height: 35px;
  width: 100px;
}

.section_2 {
  margin-top: 7rem;
}

.list_code {
  justify-content: space-between;
  display: -webkit-inline-box;
  margin-bottom: 10px;
  height: 115px;
  position: relative;
}

.list_code h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

span.number_list {
  width: 35px;
  height: 35px;
  background: #3161e4;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.line_1:after {
  width: 2px;
  content: "";
  position: absolute;
  background: #adc3fd;
  height: 90px;
  top: 35px;
  left: 17px;
}

.image_detail img {
  width: 100%;
  border-radius: 15px;
  height: 490px;
  object-fit: cover;
}

.track_row {
  margin-right: 8rem;
}

.img_qrDiv img {
  width: 100%;
  height: 290px;
  object-fit: contain;
  margin-bottom: 10px;
}

.qr_layout {
  background: #e8effc;
  padding: 20px;
  border-radius: 25px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.img_qrDiv h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.img_qrDiv {
  position: relative;
}

.arrow_next {
  position: absolute;
  right: -20px;
  top: 130px;
  font-size: 42px;
  color: #3161e4;
}

.edit_qr {
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.edit_box {
  text-align: center;
}

.edit_qr h1 {
  text-align: center;
}

.edit_box img {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
}

.edit_box h1 {
  font-size: 20px;
  font-weight: 600;
}

.qr_bubble .edit_box {
  border: 1px solid #adc3fd;
  padding: 25px 10px;
}

.qr_bubble .edit_box {
  border: 1px solid #adc3fd;
  padding: 30px 10px;
  border-radius: 15px;
}

.qr_bubble .edit_box p {
  margin-bottom: 0px;
}

// div#canvas {
//   padding: 15px;
// }

span.first-ltr-uppercase:first-letter {
  text-transform: uppercase !important;
}

.div_range {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.range_box {
  width: 200px;
}

.range_text {
  margin-right: 10px;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.main_frame {
  position: relative;
}

foreignObject.forignreltive {
  position: relative;
  height: 100%;
  width: 100%;
}

.canvasabsolute {
  display: flex;
}

.canvasabsolute svg {
  position: absolute;
  left: 5px;
  top: 9px;
}

.qr_images img {
  height: 330px;
  object-fit: contain;
}

p.fw-bold {
  margin-bottom: 0px;
}

.rowQr {
  display: flex;
  flex-wrap: wrap;
}

.max_div {
  width: 20%;
  margin-bottom: 10px;
  text-align: center;
}
.border_box canvas {
  width: 100% !important;
}
.folder_table {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img_folder {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 120px;
}
.light_greenBtn {
  background: #16c853 !important;
  border: 1px solid !important;
}
.btn_center{
  .light_greyBtn {
    background: #aeb3af !important;
    border: 1px solid !important;
  }
  .light_greyBtn:hover {
    background: #aeb3af !important;
    border: 1px solid !important;
  }
}

.table_data th {
  padding: 0px 10px;
}
.pricing_div {
  padding: 0px 2rem;
  padding-bottom: 2rem;
}
.table_data th {
  padding-bottom: 2rem;
}
.table_data {
  padding-top: 1rem;
  border: 0px;
}

// .modal-backdrop.fade.show {
//   z-index: revert-layer;
// }

/////////##### Footer css #####/////////

.footer_design {
  background: #002060;
  padding: 3rem 0rem;
  padding-bottom: 0px;
}

.footer li {
  color: #a3c0f4;
  list-style: none;
  font-size: 13px;
  margin-bottom: 15px;
  cursor: pointer;
}

.footer li:hover {
  text-decoration: underline;
}

.footer p {
  color: #a3c0f4;
  font-size: 13px;
}

h1.heading_footer {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

img.footer_logo {
  // width: 200px;
  max-width: 150px;
  width: 100%;
  margin-bottom: 1rem;
}

.footer ul {
  padding-left: 0px;
}

.flx_footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ul_div {
  width: 49%;
}

.row_footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #1961e4;
  padding: 20px 0px;
  margin-top: 1rem;
}

.row_footer p {
  color: #a3c0f4;
  margin-bottom: 0px;
}

/////////##### Footer css end #####/////////

/////////##### Plans css #####/////////

.main_plans {
  background: #fff;
  padding: 4rem 0rem;
}

h1.pricing_heading {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212121;
}

p.text_pHeading {
  text-align: center;
  font-size: 20px;
  color: #212121;
  margin-bottom: 2rem;
}

.pricing_images {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pricing_images img {
  height: 55px;
  object-fit: contain;
}

.tabs_plan {
  justify-content: center;
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.plans_btn button {
  border-radius: 30px !important;
  width: 175px;
}

.plans_btn {
  border: 1px solid #d1d1d1;
  border-radius: 30px;
  padding: 2px;
}

span.month_text {
  font-size: 12px;
  color: #888888;
}

.pricing_table h1 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.pricing_table h3 {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.btn_center {
  text-align: center;
}

.btn_center button {
  width: 190px;
  font-size: 14px;
  font-weight: 600;
}

.pricing_table h4 {
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-top: 15px;
}

th.th_main {
  width: 200px;
}

.pricing_table td {
  text-align: center;
}

.pricing_table td:first-child {
  text-align: left;
}

.pricing_table th {
  text-align: center;
}

.pricing_table th:first-child {
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pricing_table td {
  padding: 8px 8px;
  color: #212121;
  font-size: 12px;
}

.tabs_plan .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3161e4;
}

.tabs_plan button {
  color: #212121;
  font-weight: 600;
  padding: 8px 10px;
}

span.icon_done .material-icons {
  color: #16c853;
}

.rate_img h3 {
  font-size: 14px;
  color: #00c853;
  font-weight: 600;
  margin-bottom: 0px;
}

.rate_img img {
  width: 35px;
  object-fit: contain;
}

.rate_img {
  position: absolute;
  right: -95px;
  top: -18px;
}

.list_popular {
  position: relative;
}

p.topular_text {
  background: #3161e4;
  width: fit-content;
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 4px;
  position: absolute;
  top: -16px;
  left: 0;
  right: 0;
  margin: auto;
}
/////////##### Plans css end #####/////////

.link_color {
  color: #a3c0f4;
}

.link_color:hover {
  color: #a3c0f4;
}

.status_primary {
  margin-bottom: 0px;
  background-color: #e8effc;
  color: #3c62e2 !important;
  padding: 5px 14px;
  border-radius: 4px;
}

.status_danger {
  margin-bottom: 0px;
  background: #fae6e6;
  color: #c80000;
  padding: 5px 14px;
  border-radius: 4px;
}

.btn_line {
  white-space: nowrap;
}
.qr_btn {
  margin-top: 1rem;
}
.select_layout span.form-select.position-relactive.pointer {
  display: flex;
  align-items: center;
}
.list-group-item.d-flex {
  flex-wrap: wrap;
}
.list_an div {
  gap: 10px;
}

/////////##### Plan FAQ css #####/////////

.accordion {
  display: flex;
  flex-direction: column;
  font-family: "Sora", sans-serif;
  /* max-width: 660px; */
  min-width: 320px;
  margin: 25px auto;
  /* padding: 0 50px; */
}

.accordion h1 {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
}

.faq_div {
  margin-top: 5rem;
}

.green_light {
  background: #00c853 !important;
  border: 1px solid #00c853 !important;
}

.btn_center button:hover {
  background: #00c853 !important;
  border: 1px solid #00c853 !important;
}

.accordion-item {
  margin-top: 16px;
  border: 1px solid #fcfcfc;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.accordion-item .accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 15px;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 14px 20px;
  box-sizing: border-box;
  align-items: center;
}

.accordion-item .accordion-item-desc {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: #444;
  border-top: 1px dashed #ddd;
  padding: 10px 20px 20px;
  box-sizing: border-box;
}

.accordion-item input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc {
  display: block;
}

.accordion-item
  input[type="checkbox"]:checked
  ~ .accordion-item-title
  .icon:after {
  content: "-";
  font-size: 20px;
}

.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after {
  content: "+";
  font-size: 20px;
}

.accordion-item:first-child {
  margin-top: 0;
}

.accordion-item .icon {
  margin-left: 14px;
}

.accordion-item.div_faqrow {
  background: #f4f4f4;
}

.padding_box {
  padding: 0px 10px;
  padding-bottom: 10px;
}
.tabs_icon {
  flex-wrap: nowrap;
}
.btn_width {
  margin-right: 2.5rem;
}

/////////##### Plan FAQ css end #####/////////

@media (max-width: 1462px) {
  .table-responsive {
    display: block;
  }
}

////////##### UserBulk css #####//////////

.dot_div p {
  color: #888888;
  margin-bottom: 10px;
}

.dot_div h5 {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3161e4;
  justify-content: center;
}

button.btn_download {
  border: 1px solid #3161e4;
  border-radius: 5px;
  color: #3161e4;
  background: #fff;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 10px;
}

button.btn_download .material-icons {
  font-size: 14px;
}

.div_download {
  display: flex;
  flex-wrap: wrap;
}

.dot_div {
  height: 220px;
  border: 1px dashed #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ul_row {
  margin-bottom: 2.5rem;
}

.user_bulkDiv .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3161e4;
  background-color: unset !important;
  border-bottom: 2px solid;
  border-radius: 0px;
  width: fit-content;
  padding: 10px 0px;
  font-weight: 500;
  padding-top: 0px;
}

.user_bulkDiv .nav-link {
  color: #212121;
  background-color: unset !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  border-radius: 0px;
  width: fit-content;
  padding: 10px 0px;
  margin-right: 20px;
  padding-top: 0px;
}

.user_bulkDiv {
  border-bottom: 1px solid #e5e5e5;
}

.ul_row li {
  list-style: auto;
}

.ul_row li {
  list-style: auto;
}

span.span_heading {
  font-weight: 600;
}

.img_rowRight img {
  width: 100%;
  object-fit: contain;
}

.teb_user li {
  margin-bottom: 5px;
}

button.btn_download:hover {
  background: #3161e4;
  color: #fff;
  border: 1px solid;
}

.user_bulkDiv .nav-link:hover {
  color: #3161e4;
}

////////##### UserBulk css End #####//////////

.border-left-right {
  border-left: 1px solid;
  border-right: 1px solid;
}

////////##### Analytics css #####//////////

.graf_Section {
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.graf_Sec {
  height: 538px;
}


.row_form2 {
  padding: 15px;
}

h1.heading_h1row {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.row_design {
  background: #fff3e6;
  padding: 15px;
  border-radius: 8px;
}

.row_design2 {
  background: #e8effc !important;
}

.row_design3 {
  background: #e6faee !important;
}

.row_design h1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.color_1 {
  font-size: 28px;
  font-weight: 700;
  color: #ff8400;
  margin-bottom: 0px;
}

.color_2 {
  color: #1961e4;
}

.color_3 {
  color: #00c853;
}

.row_design p {
  font-size: 12px;
}

.row_design p .material-icons {
  font-size: 25px;
  top: 9px;
  position: relative;
}

span.plus_item {
  background: #00c853;
  color: #fff;
  font-weight: 700;
  padding: 5px 4px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 11px;
}

.red_item {
  background: #ff3d17 !important;
}

.row_design p .material-icons {
  font-size: 25px;
  top: 11px;
  position: relative;
  margin-right: 5px;
}

table.table_design tr {
  border-top: 1px solid #dee2e6;
}

table.table_design th {
  padding: 10px 10px;
  font-size: 13px;
}

table.table_design td {
  padding: 10px 10px;
  font-size: 13px;
}

.table_design tr:first-child {
  border-top: none;
}

.table_box {
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.design_scan {
  margin-top: 2rem;
}

.table_design tr:nth-child(even) {
  background-color: #fff;
}

.border_box {
  border: 1px solid #dee2e6;
  min-height: 255px;
  padding: 15px;
  border-radius: 8px;
  background: #FFF;
}

th.td_table {
  width: 55px;
}

th.scans_div {
  width: 90px;
}

.progress_bar {
  height: 22px;
  border-radius: 0px;
  background: none;
}

.progress_row {
  background: #3161e4;
  margin: 0px !important;
}

th.heading-th {
  width: 135px;
}

////////##### Analytics css End #####//////////

.page_main {
  background: #fff;
  margin: 2rem 0rem;
}

h1.page_heading {
  font-size: 24px;
  text-align: center;
  padding: 1.5rem 0px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  background: #0f2060;
  color: #fff;
}

.layout-page h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

.layout-page {
  padding: 1rem 1.5rem;
}

.page_main p {
  font-size: 14px;
  color: #404040;
}

.page_main p {
  font-size: 14px;
  color: #404040;
}

.details_col {
  background: #fff;
  border-radius: 8px;
}

h1.detail_heading {
  font-size: 16px;
  margin-bottom: 0px;
  padding: 15px 15px;
  border-bottom: 1px solid #dee2e6;
}

.box_detail {
  padding: 20px;
}

.back_ground {
  display: flex;
  justify-content: space-between;
}

.back_ground h3 {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.back_ground h1 {
  font-size: 32px;
  font-weight: 700;
  color: #00c853;
  margin-bottom: 0px;
}

.back_ground {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6faee;
}

.back_ground {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6faee;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.back_ground2 {
  background: #e8effc !important;
}

.back_ground2 h1 {
  color: #1961e4 !important;
}

.box_detail p {
  font-size: 14px;
  margin-bottom: 8px;
  // display: flex;
}

span.green_textLine {
  font-weight: 800;
  color: #16c853;
}

.group_div {
  display: flex;
}

.icon_images {
  display: flex;
  flex-wrap: wrap;
}

.icon_images img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 5px;
  cursor: pointer;
}

.lightbulb_icon {
  color: #ff8400;
  margin-right: 5px;
}

.primary_btn1 {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  color: #3161e4;
  border: 1px solid #3161e4;
  background-color: #ffebcd00;
}

.row_to {
  display: flex;
  flex-wrap: wrap;
}

.col_box {
  margin-right: 1rem;
}

///////////////////####### Modal_sign ########//////////////////////

.sign_div-modal {
  max-width: 100% !important;
}

.login_bx-modal {
  max-width: 100%;
  border: 0px;
}

.modal_signup {
  max-width: 935px;
  border-radius: 15px;
  margin: auto;
  margin-top: 5.3rem;
}

.h_modal h6 {
  font-size: 18px;
}

.done_ixons p {
  display: flex;
}

.done_ixons .material-icons {
  color: #16c853;
  margin-right: 10px;
}
span.text-primary.fw-bold.float-end {
  margin-left: 10px;
}

///////////////////####### Modal_sign end ########//////////////////////

.div9row2 button {
  width: 265px !important;
  margin-left: auto;
}

.rowSearch {
  display: flex;
  justify-content: space-between;
}

p.fw-bold {
  margin-bottom: 0px;
}

button.btn.primary-button {
  color: #3161e4;
  border: 1px solid #3161e4;
  font-size: 14px;
  font-weight: 600;
}

.left {
  position: fixed;
  bottom: 32px;
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px -4px #000;
  padding: 2px 1.9px;
  font-size: 24px;
  color: #4e4e4e;
  cursor: pointer;
  left: 234px;
  z-index: 99;
}

.right {
  position: fixed;
  bottom: 32px;
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px -4px #000;
  padding: 2px 2.8px;
  font-size: 24px;
  color: #4e4e4e;
  cursor: pointer;
  left: 4px;
  z-index: 99;
}

.sidebar_active .main {
  width: calc(100% - 85px) !important;
}

.img_sidebar2 {
  display: none;
}

.pills_row {
  width: 100%;
}

span.white_space {
  white-space: nowrap;
}
.list-format h4 {
  font-size: 20px;
}
.layout_qr span {
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.stsearch-box input:focus-visible {
  outline: none;
}
span.img_folder img {
  margin-right: 5px;
}
img.img-bg-set {
  width: 150px;
  height: 130px;
}
img.img-fluid.pointer {
  width: 20px;
}
.an_div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.second_div .table-responsive {
  min-height: 320px;
}
.table_box {
  max-height: 590px;
  overflow: auto;
}
table.table_design thead {
  position: sticky;
  top: 0;
  background: #fff;
}
button.btn_download:hover .material-icons {
  color: #fff;
}
.qr_multi svg {
  display: flex;
  justify-content: center;
  margin: auto;
}
.left_list {
  width: 78%;
}

.prophone .form-control {
  width: 100% !important;
  }

// .main_framenot:not(:nth-child(3)) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2not:not(:nth-child(3)) {
//   opacity: 0;
//   display: none;
// }

// .main_frame2:nth-child(4) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2:nth-child(5) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2:nth-child(6) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2:nth-child(7) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2:nth-child(8) {
//   opacity: 0;
//   display: none;
// }
// .main_frame2:nth-child(9) {
//   opacity: 0;
//   display: none;
// }

.main_frame2:nth-child(4) {
  display: none;
}
.main_frame2:nth-child(4) {
  display: none;
}
.main_frame2:nth-child(5) {
  display: none;
}
.main_frame2:nth-child(6) {
  display: none;
}
.main_frame2:nth-child(7) {
  display: none;
}
.main_frame2:nth-child(8) {
  display: none;
}
.main_frame2:nth-child(9) {
  display: none;
}



.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
}
.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.no_data {
  font-size: 18px;
  color: #5a5a5a;
}
.apps_modal .padding_box {
  height: calc(100vh - 195px);
  overflow: auto;
  padding: 0px 15px;
}
.apps_modal {
  margin-top: 5.3rem;
  margin-left: 15px;
  margin-right: 15px;
}
.div_header {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}
.left_div {
  width: 60%;
}
.img_lock {
  display: flex;
  justify-content: end;
  gap: 0px 8px;
  flex-wrap: wrap;
}
.close_fa {
  position: absolute;
  right: -22px;
  top: -22px;
  font-size: 20px;
  background: #fff;
  border-radius: 50%;
}



@media (max-width: 1462px) {
  .table-responsive {
    display: block;
  }
}

@media (max-width: 1365px) {
  .range_box {
    width: 161px;
  }

  .temp-box:before {
    left: 20px !important;
    width: 82% !important;
  }

  .h6-btn-active {
    font-size: 12px !important;
  }

  .dotsbtn {
    font-size: 12px;
  }

  .h6-btn-active::before {
    left: 13px !important;
  }

  .dotsbtn:before {
    left: 18px !important;
  }
}

@media (max-width: 1299px) {
  .textp {
    font-size: 9px !important;
  }

  img.setimg {
    height: 25px;
    border: 0px;
    object-fit: scale-down;
    width: 25px;
  }
}

@media (min-width: 1100px) {
  .sidebar_active .main-sidebar {
    width: 85px;
  }

  .sidebar_active .name_px span {
    display: none;
  }

  .sidebar_active .name_px img {
    margin-left: 0px;
  }

  .sidebar_active .img_sidebar {
    margin-left: 0px;
    display: none;
  }

  .sidebar_active .side_head {
    display: none;
  }

  .sidebar_active .bonus_div {
    display: none;
  }

  .sidebar_active .alex-text {
    display: none;
  }

  .sidebar_active .sidebar_box {
    width: 52px;
  }

  .sidebar_active .img_sidebar2 {
    display: block;
  }

  .sidebar_active .editLogo img {
    max-width: 45px !important;
  }

  .sidebar_active .main-sidebar .nav .nav-item .nav-link i {
    font-size: 22px;
    margin-right: 0px !important;
  }

  .sidebar_active .right {
    left: 70px;
  }

  .sidebar_active .a.navbar-brand {
    display: none;
  }

  .sidebar_active .span.fw-bold.fs-5 {
    margin-left: 1rem;
  }

  .sidebar_active .main-sidebar .nav .nav-item > .nav-link:hover,
  .main-sidebar .nav .nav-item.active > .nav-link {
    border-radius: 5px 5px 5px 5px;
  }

  .sidebar_active .main-sidebar {
    z-index: auto !important;
  }
}
@media (max-width: 1099px) and (min-width: 991px) {
  .btns_wrap {
    flex-wrap: wrap;
  }
  form.btns_wrap button {
    display: flex;
    width: 100%;
    margin-left: 0px !important;
    justify-content: center;
    margin-top: 10px;
  }
}
@media (max-width: 1099px) {
  .sidebar_height {
    position: fixed;
    height: 100vh;
    z-index: 9;
    top: 0px;
  }

  .sidebar_active .sidebar_height {
    display: none;
  }

  .main-wrapper main.main {
    width: calc(100% - 0px) !important;
  }

  .sidebar_height {
    display: none;
  }

  .sidebar_active .sidebar_height {
    display: block;
  }

  .right {
    left: 234px;
    transform: rotate(180deg);
  }

  .left {
    left: 4px;
    transform: rotate(-180deg);
  }
}

@media (max-width: 667px) {
  .row_22 {
    flex-wrap: wrap;
  }
  .right_list img {
    border-left: none;
    padding-left: 0px;
  }
  .plans_btn button {
    padding: 5px 15px;
    width: 125px;
  }
  .box_col2 {
    width: 49%;
    margin-bottom: 0px !important;
  }
  .left_list {
    width: 100%;
  }
  .border_leftclass {
    width: 100%;
    margin-left: 0px;
    border-left: 0px;
    padding-left: 0px;
  }
  .border_leftclass {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .tab-pills .nav-link {
    min-width: 126px !important;
  }

  .vcard_name p {
    font-size: 16px !important;
  }
  .layout_vcard h2 {
    font-size: 25px !important;
  }

  .banner_back img {
    height: 150px !important;
    width: 150px !important;
    top: 32px !important;
  }
  .vcard_name h2 {
    font-size: 28px !important;
    line-height: 25px !important;
  }
  .banner {
    height: 150px !important;
  }
  .right_side {
    height: 100% !important;
  }
  .trash_div {
    flex-wrap: wrap;
  }
  .listing_fl {
    flex-wrap: wrap;
    gap: 10px;
  }

  .accordion {
    padding: 0 15px;
  }

  .accordion h1 {
    font-size: 22px;
  }
  .pricing_images img {
    height: 30px !important;
  }

  .dropdown_flex {
    flex-wrap: wrap;
  }

  .gradient-div {
    flex-wrap: wrap;
  }

  .rowDiv h6 {
    white-space: nowrap;
  }

  .rowDiv {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .pills_tabs button {
    margin-right: 20px !important;
  }

  .header_tabs button {
    padding: 0px 10px;
  }

  .box_images {
    justify-content: center !important;
  }

  .activeurl {
    width: 100%;
  }

  .track_row {
    margin-right: 3rem !important;
  }

  p.accopunt {
    top: 25px !important;
  }

  .right_side {
    padding: 2rem 15px;
  }

  .login_img {
    top: 25px;
  }

  .main-navbar {
    background-color: #fff;
    color: #fff;
    height: 72px;
    margin-left: 0px !important;
  }

  .btn-flex {
    width: 100%;
    text-align: end;
    margin-top: 10px;
  }

  .dashboard_row {
    margin-bottom: 15px;
  }

  .value_orders h1 {
    font-size: 14px;
  }

  .value_orders p {
    margin-bottom: 2px;
  }
  .div9row2 button {
    width: 170px !important;
  }

  .row_footer {
    flex-wrap: wrap;
  }

  .arrow_next {
    display: none;
  }
  .btns_blue {
    justify-content: end;
    margin-top: 10px;
  }
}

// Css by Manjinder
@media (max-width: 480px) {
  .graf_Sec {
    height: 603px;
}
  // admin module
  .login-bx h5 {
    font-size: 14px;
    margin-top: 10px;
  }
  .select_layout.ms-2 {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .login-bx h4 {
    font-size: 14px;
    margin-top: 10px;
  }
  .para_forget {
    font-size: 12px;
  }
  .home_q_header {
    font-size: 15px;
  }

  .pills_tabs {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .header_tabs button {
    white-space: nowrap;
  }

  .h6_login {
    font-size: 12px !important;
  }

  .pills_tabs::-webkit-scrollbar {
    height: 0px;
  }

  a.m-sign {
    font-size: 12px !important;
  }

  .pills_tabs {
    width: 100% !important;
  }

  .tab-pills .nav-link {
    min-width: 90px !important;
  }

  .home_q_desc {
    font-size: 12px;
  }

  .gdpr-btn {
    margin-bottom: 10px;
  }

  .home_detail {
    padding-top: 1rem;
  }

  .sections_1 h1 {
    margin-bottom: 2rem;
    font-size: 18px;
    margin-top: 1rem;
  }

  .row_images {
    height: 25px;
  }

  .image_rowdiv {
    flex-wrap: wrap;
    gap: 10px;
  }

  .main_heading {
    font-size: 15px;
    font-weight: 500;
  }

  .list_code h1 {
    font-size: 15px;
    margin-bottom: 2px;
  }

  .list_desc {
    font-size: 12px;
  }

  .section_2 {
    margin-top: 1rem;
  }

  // .track_row {
  //   margin-right: 0;
  // }
  .image_detail {
    text-align: center;
  }

  canvas {
    width: 50% !important;
  }

  .image_detail_img {
    height: 300px !important;
    width: 250px !important;
  }

  .stat_heading {
    font-size: 14px;
  }

  .urlbtn input.form-check-input,
  .urlbtn input.form-check-input:focus {
    width: 16px !important;
    height: 17px !important;
  }

  .img_qrDiv img {
    height: 170px;
    margin-bottom: 2px;
  }

  .arrow_next {
    font-size: 25px;
    display: none;
  }

  .img_qrDiv h3 {
    font-size: 15px;
  }

  .edit_qr {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .edit_box h1 {
    font-size: 15px;
  }

  .modal-body h4 {
    font-size: 15px;
  }

  .box_images img {
    width: 100px;
    height: 100px;
    margin-bottom: 7px;
    margin-right: 8px;
  }

  .main-wrapper main.main {
    width: 100% !important;
    padding-top: 12px !important;
  }

  .select-type {
    font-size: 15px;
  }

  .dot_div {
    height: 115px;
    padding: 10px;
  }

  .ul_row {
    margin-bottom: 1rem;
  }

  h1.heading_row {
    margin-bottom: 10px;
  }

  .btns_tabs .material-icons {
    font-size: 14px;
  }

  .btn_download {
    margin-right: 5px;
  }

  h1.heading_row {
    padding-top: 0px;
  }

  // Css by Manjinder

  // Qr code scanner page
  .scanner-pills .nav-item .nav-link {
    font-size: 12px;
  }
  .data-img {
    width: 11px;
    margin-left: 0;
  }
  // bulk qr code page
  .data-pills .nav-item .nav-link {
    width: 100%;
    font-size: 12px;
  }
  // Faq page
  .faq-page h2 {
    font-size: 25px;
  }
  .faq-title p {
    font-size: 12px;
  }
  .faq_1 {
    width: 100%;
  }
  .faq_cat {
    margin-top: 15px;
    width: 100%;
  }
  span.first-ltr-uppercase {
    font-size: 14px;
  }
  .accordion-body {
    font-size: 12px;
  }

  // blog view

  .subscribe-option {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .blog-title img {
    height: 170px !important;
  }
 
  //  contact us
  .form-detail h1 {
    font-size: 40px !important;
  }
  .ct_child {
    margin-bottom: 0 !important;
    font-size: 12px !important;
  }

  .box_navbar .material-icons {
    font-size: 12px !important;
  }
  .box_navbar span {
    font-size: 12px !important;
  }
  .box_navbar button {
    padding: 2px 10px;
    font-size: 10px !important;
    margin-top: 6px;
  }
  // qr listing
  .trash_div {
    flex-direction: column;
    gap: 10px;
  }
  .search_trash_div {
    width: 100%;
  }
  .dropdown_flex {
    flex-wrap: wrap;
  }

  .select_dropdown {
    width: 100%;
  }
  .row_22 {
    display: block;
  }
  .link_btn {
    overflow-wrap: anywhere;
  }

  .list_rowqr .layout_qr {
    margin-right: 1rem;
  }
  .layout_qr b {
    font-size: 13px;
  }
  .border_leftclass {
    margin: 10px 0px;
  }
  .row_qrcode {
    flex-wrap: wrap;
  }

  // .second_div .select_input{
  //   width: 100%;
  // }
  // account setting
  .t-h6 {
    font-size: 12px;
  }

  .main-div {
    margin: 0 !important;
  }

  .table-responsive .table th {
    font-size: 12px !important;
  }
  .table thead th {
    white-space: nowrap !important;
  }
  .main-table .search_bar {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .max_div {
    width: 25% !important;
  }
}
@media(max-width:352px){
  .graf_Sec {
    height: 650px;
}
}

@media (max-width: 340px) {
  .div_img4 img {
    width: 100% !important;
    object-fit: contain;
  }

  .main_frame {
    position: relative;
  }

  foreignObject.forignreltive {
    position: relative;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 340px) {
  .div_img4 img {
    width: 100% !important;
    object-fit: contain;
  }
}

// Analytics Date Range Filter CSS
.analytics_date_filter {
  position: absolute;
  z-index: 99 !important;
  background-color: #ffffff;
  padding: 4px;
  border: 1px solid #ffffff;
  border-radius: 9px;
  top: 2px;
  box-shadow: 2px 2px 10px -4px;
  right: -200px;
}

@media (max-width: 1599px) {
  .analytics_date_filter {
    right: -125px;
  }
  .analytics_date_filter.analytics_date_filter2 {
    right: -684px !important;
}
}

@media (max-width: 991px) {
  .analytics_date_filter.analytics_date_filter2 {
    right: -584px !important;
}
  .bars_blog {
    position: absolute;
    right: 0px;
    background: #ffffff;
    padding: 5px 8px;
    z-index: 9;
    box-shadow: 0px 0px 20px -8px;
    top: -41px;
  }
  .nav_dropdown {
    position: absolute;
    z-index: 9;
    background: #fff;
    top: 0px;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 18px -12px;
    border-radius: 10px;
  }
  .btns_wrap {
    width: 100%;
  }
  .blog-detail-page {
    margin-top: 3rem !important;
  }
  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }
  .analytics_date_filter {
    left: -85px;
  }
  .pricing_images img {
    height: 40px;
    object-fit: contain;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrStaticRanges {
    display: flex;
    flex-wrap: wrap;
    flex-direction: unset !important;
    width: 100%;
  }
  .rdrInputRanges {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 737px) {
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 450px;
  }
  .analytics_date_filter.analytics_date_filter2 {
    right: -455px !important;
    left: 0px;
}
  .analytics_date_filter {
    left: -142px;
  }
}
@media(max-width:706px){
  .analytics_date_filter.analytics_date_filter2 {
    right: -280px !important;
    left: -175px !important;
}
}
@media (max-width: 575px) {
  .analytics_date_filter {
    left: -140px;
  }
  .main_plans {
    padding: 2rem 0rem;
  }
  .accordion {
    padding: 0 0px;
  }
  .tab-pills .nav-link {
    min-width: 94px !important;
  }
  .flex_details h6 {
    font-size: 12px;
  }
  .div9row2 button {
    width: 100px !important;
  }

  .slider-input {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .div9row2 svg {
    width: 18px;
  }
  .pricing_images img {
    width: 32%;
    margin-bottom: 1.5rem;
  }
  a.navbar-brand {
    width: 170px !important;
  }

  .slider-input {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .Sales_row p {
    width: unset;
  }
  .pricing_images {
    flex-wrap: wrap;
  }
  .tabs_plan {
    margin-bottom: 1rem !important;
  }
  .pricing_div {
    padding: 0px 1rem !important;
  }
  .dropdown_flex {
    display: flex;
  }
  .dropdown_flex div {
    width: fit-content;
    flex-wrap: nowrap;
  }
  .modal-dialog.modal-dialog-centered.w-75 {
    width: 100% !important;
  }

  .modal-dialog.modal-dialog-centered.w-75 {
    max-width: 100% !important;
  }

  img.setimg {
    height: 25px;
    width: 25px;
  }

  .modal-dialog.modal-dialog-centered.w-75 {
    max-width: 100% !important;
  }

  .header_list {
    flex-wrap: wrap;
  }

  .btn_flex {
    margin-left: 10px;
  }

  .mainarea {
    padding: 10px 0px !important;
  }

  .orders_layout {
    justify-content: left;
    flex-wrap: wrap;
  }

  .value_orders {
    width: 29%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.list_an .analytics_date_filter div {
  gap: 0px !important;
}
@media (max-width: 499px) {
  .analytics_date_filter.analytics_date_filter2 {
    right: -265px !important;
    left: 0px !important;
}
.faq-page {
  padding: 50px 0 !important;
}
  .rdrDateDisplay {
    display: flex;
    flex-wrap: wrap;
  }
  .banner_back img {
    height: 95px !important;
    width: 95px !important;
    top: 0px !important;
  }
  .layout_vcard {
    padding: 20px;
    padding-top: 2rem;
  }
  .vcard_name p {
    font-size: 14px !important;
  }
  img.img-bg-set {
    width: -webkit-fill-available;
    height: 250px;
  }
  .vcard_name h2 {
    font-size: 24px !important;
    line-height: 22px !important;
  }
  .rdrMonths {
    display: flex;
    flex-wrap: wrap;
  }
  .small-blog .small_image {
    height: 240px !important;
  }

  .rdrMonth {
    width: 100%;
  }
  .analytics_date_filter {
    left: -30px;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 340px;
  }
}
@media (max-width: 480px) {
  .analytics_date_filter {
    left: -10px;
  }
}
@media (max-width: 420px) {
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 260px;
  }
}

p.download_text {
  margin-top: 5rem;
  font-size: 18px;
  color: #5ea649;
  font-weight: 700;
}
.columns {
  background-color: #ffffff;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 9;
  padding: 2rem;
  text-align: center;
}
.qr_hide {
  display: none;
}

.banner {
  height: 300px;
  // background-image: url("assets/img/background_banner.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 15px;
}
.banner_back {
  height: 100%;
  background: #1961e4e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;
  padding: 2rem;
}
.vcard_name {
  width: 65%;
  text-align: center;
}

.vcard_name h2 {
  font-size: 70px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: 600;
  line-height: 65px;
}
.vcard_name p {
  font-size: 24px;
  margin-bottom: 0px;
  color: #fff;
}
.banner_back img {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  position: relative;
  top: 33px;
  border: 4px solid #fff;
  box-shadow: 0px 0px 15px -9px #000;
  margin-right: 10px;
}
.vcard_page {
  background: #fff;
  box-shadow: 0px 0px 15px -9px #000;
  border-radius: 15px;
}
.layout_vcard {
  padding: 20px;
  padding-top: 4rem;
}
.layout_vcard h2 {
  font-weight: 600;
}
span.text_blue {
  color: #1961e4e0;
}
.layout_vcard p {
  font-size: 15px;
  margin-bottom: 0px;
}
.flex_vcard {
  display: flex;
}
.flex_vcard p {
  width: 150px;
}
.flex_vcard {
  display: flex;
  margin-bottom: 10px;
}
button.btn.btn_primary {
  border: 1px solid #afb4b8;
  margin-top: 1rem;
}
button.btn.btn_primary:hover {
  border: 1px solid #1961e4e0;
  color: #1961e4e0;
}
.flex_vcard span {
  color: #747474;
}

//gourav css
// header css
@media (max-width: 1200px) {
  .linK_padding {
    padding: 8px 16px !important;
    font-size: 13px !important;
  }
}
span.inline_bx {
  white-space: nowrap;
}
.searchRow {
  position: relative;
}
.searchRow .material-icons {
  position: absolute;
  top: 14px;
  right: 8px;
  font-size: 16px;
  cursor: pointer;
}
.searchRow input {
  padding-right: 25px;
}
.date_select {
  position: relative;
  padding: 12px 8px;
}
.width_qr {
  // width: 300px;
  margin: auto;
}
.expiry_modal {
  max-width: 580px;
}
.analytics_date_filter2 {
  right: -550px !important;
}
.height_box {
  min-height: 158px;
}

.img_qr img {
  width: 100%;
}
.accordion-button:focus {
  box-shadow: none;
}
.faq_1 input {
  height: 43px;
}
.bottom-subscribe {
  gap: 10px;
}
.inputbar {
  height: 43px;
}
.back-btn {
  display: flex;
  justify-content: end;
}
#chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 400px;  // Adjust as needed
}

.pac-container {
  display: none;
}

@media(max-width:707px){
  .graf_Sec {
    height: 571px;
}
}

// gourav css

//analytics page css
.main_select .date_select{
  position: relative;
  padding: 6px 12px;
  height: 38px;
  font-size: 13px;
  color: #8d8b8b;
  line-height: 25px;
}
.select_allstatus .form-select {
  height: 38px;
  font-size: 14px;
  // color: #8d8b8b;
}
// EditQrCodes page css
.set_list_bx .left_list {
  width: 100%;
}
.switch_btnline input{
  border: 1px solid #bfbfbf;
}
.back-gradient {
  background: linear-gradient(
    180deg,
    #eaf2fa 0%,
    #eaf2fa 0.01%,
    #9cceff 70.31%,
    #50a6f5 100%
  ) !important;
}
img.dotted_img {
  width: 45px;
  height: 45px;
  left: -22px;
  top: -11px;
  position: relative;
  object-fit: none;
}
.qr-body-actve {
  padding: 8px;
  border: 3px solid #3c62e2;
  border-radius: 8px;
}
.qr-body-actve img.dotted_img {
  width: 40px;
  height: 40px;
  left: -9px;
  top: -8px;
  position: relative;
  object-fit: none;
}
.search_pos {
  position: absolute;
  top: 13px;
  right: 28px;
  color: #323232;
}
.search_trash_div input {
  padding-right: 25px;
}
.payment_modal {
  max-width: 800px;
}
.modal_payment h3 {
  font-size: 16px;
}
.modal_payment h2 {
  font-size: 24px;
  font-weight: 700;
}
.modal_payment p {
  color: #777;
  margin-bottom: 0px;
}
.pay_div {
  border-bottom: 1px solid #dee2e6;
}
.modal_payment .card-holder-input{
  width: 100% !important;
height: 40px !important;
border: 1px solid #000 !important;
}
.modal_payment input {
  width: 45px !important;
  height: 22px;
}
.modal_payment h4 {
  font-size: 18px;
  font-weight: 700;
}
.payment_modal .modal-body {
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding: 20px 30px;
}
.fade_code {
  background: #0000004f;
}
.times_close {
  margin-left: 1rem;
  color: #7a7a7a;
  font-size: 20px;
}
.times_close:hover {
 color: #000 !important;
}
p.download_active {
  border: 1px solid #1961e4;
}
.toggle_div {
  padding-left: 10px;
}
.toggle_div input {
  position: relative;
  right: -42px;
}
.card-holder-input{
  width: 100% !important;
height: 40px !important;
border: 1px solid #000 !important;
}
@media(min-width:992px){
  .inputs-width-down{
width: 70%;
  }
}