@import "../../../scss/mixins";

.main-wrapper {
  background-color: #eee;

  main.main {
    width: calc(100% - 250px);
    // padding-top: 30px !important;
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
  }
}

.editLogo {
  display: inline-block;
  position: relative;
  cursor: pointer;

  > i {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

// .logocls {
//   width: 100%;
//   max-width: 185px !important;
// }

.dash-title {
  position: relative;
  background-color: #f6f8fb;
  padding: 11px 19px;
  font-size: 28px;
  line-height: normal;
  border-radius: 6px;
  margin-bottom: 30px;

  .total {
    font-size: 14px;
    float: right;
    font-weight: 600;
    margin-top: 10px;
  }
}

.main-sidebar {
  position: relative;
  background-color: #f9fafb;
  width: 250px;
  height: 100vh;
  margin-top: -72px;
  border-right: 1px solid #e8e8e8;
  position: sticky;
  top: 0px;
  overflow: auto;

  .sidebar-brand img {
  }
}

// @media (max-width: 767px) {
//   .main-wrapper main.main {
//     width: calc(100% - 250px);
//     padding-top: 70px ;
//     padding-left: 0;
//     padding-right: 0;
//     margin-left: auto;
// }
// }

@media (max-width: 467px) {
  .row.uploaded-image-row > div {
    max-width: 50%;

    .uploaded-image img {
      height: 100px;
    }
  }
}

img.hide-logo {
  display: none;
}

.active-sidebar img.hide-logo {
  display: block !important;
  width: 20px;
}

.show-logo {
  display: block;
}

.active-sidebar .show-logo {
  display: none;
}
.min-sidebar ~ div > .main-sidebar .sidebar-brand {
  visibility: visible !important;
  width: 20px !important;
  margin: 0 auto !important;
}

.sidebar-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.light-btn {
  background: #00c853;
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
}
.light-btn:hover {
  background: #00c853 !important;
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
}
.dark-btn {
  background: #1961e4;
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
}
.dark-btn:hover {
  color: #fff;
  text-decoration: none;
}
.light-btn img {
  margin: 0 0 0 10px;
}
.dark-btn img {
  margin: 0 0 0 10px;
}
